import { Box, BoxProps, Collapse } from '@mui/material'
import { kebabCase } from 'change-case'
import { useEffect, useState } from 'react'

import { ListItemButton, ListItemButtonProps } from 'components/List'
import { GLOBAL_BORDER_RADIUS } from 'providers/material-ui/theme/constants'
import { COLLAPSED_Y_DEFAULT } from './constants'
import { CollapsedY, RenderItemFn, SidebarItem, UseSidebar, UseSidebarProps } from './types'

export const useSidebar = ({ collapsedX, onCollapsedX }: UseSidebarProps): UseSidebar => {
  const [collapsedY, setCollapsedY] = useState<CollapsedY>(COLLAPSED_Y_DEFAULT)

  useEffect(() => {
    if (collapsedX === true) {
      setCollapsedY(COLLAPSED_Y_DEFAULT)
    }
  }, [collapsedX])

  const BoxStyled = ({
    gapAfter,
    ...props
  }: BoxProps & Pick<SidebarItem, 'gapAfter'>): JSX.Element => (
    <Box
      {...props}
      sx={{ flexGrow: gapAfter ? 1 : 0 }}
    />
  )

  const GroupItem = ({
    item,
  }: {
    item: Required<Pick<SidebarItem, 'group'>> & SidebarItem
  }): JSX.Element => {
    const { group, icon, title } = item

    const handleOnCollapseY = (): void => {
      if (collapsedX) {
        onCollapsedX(false)
      }

      setCollapsedY({ ...collapsedY, [group]: !collapsedY[group] })
    }

    return (
      <NavItem
        collapsedX={collapsedX}
        collapsedY={collapsedY[group]}
        icon={icon}
        onCollapseY={handleOnCollapseY}
        text={title}
      />
    )
  }

  const SingleItem = ({ item }: { item: SidebarItem }): JSX.Element => {
    const { color, endAdornment, icon, id, marginBottom, newTab, onClick, shifted, title, to } =
      item

    return (
      <Box sx={{ ml: shifted ? 2 : 0 }}>
        <NavItem
          endAdornment={endAdornment}
          icon={icon}
          id={id}
          newTab={newTab}
          onClick={onClick}
          sx={{
            ...(color ? { backgroundColor: color.main } : {}),
            ...(marginBottom ? { mb: 2 } : {}),
          }}
          text={title}
          to={to}
        />
      </Box>
    )
  }

  const NavItem = ({ id, ...props }: ListItemButtonProps): JSX.Element => (
    <ListItemButton
      {...props}
      collapsedX={collapsedX}
      data-cy={`nav-item-${kebabCase(id || props.text)}`}
      selectable
      sx={{
        ...props.sx,
        borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
      }}
    />
  )

  const renderItem: RenderItemFn = (item): JSX.Element => {
    const { gapAfter, group, hidden, subItems, title } = item
    const isHidden = hidden || (group && subItems?.every(subItem => subItem.hidden))

    if (isHidden) return <div key={title}></div>

    return (
      <BoxStyled
        gapAfter={gapAfter}
        key={title}
      >
        {group ? (
          <>
            <GroupItem item={{ ...item, group }} />

            {subItems?.length && (
              <Collapse in={collapsedY[group]}>
                {subItems.map(
                  (subItem: SidebarItem): JSX.Element => (
                    <div key={subItem.title}>{renderSubItem(subItem)}</div>
                  ),
                )}
              </Collapse>
            )}
          </>
        ) : (
          <SingleItem item={item} />
        )}
      </BoxStyled>
    )
  }

  const renderSubItem = (item: SidebarItem): JSX.Element => {
    const { group, hidden, title } = item

    if (hidden) return <></>

    return (
      <>
        {group ? (
          renderItem(item, -1)
        ) : (
          <SingleItem
            item={item}
            key={title}
          />
        )}
      </>
    )
  }

  return { renderItem }
}
