import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { parseSchemaParametersToDTO } from 'features/globalSetup/utils/parameter'
import { DomainPrebidModuleParamsDTO } from 'features/inventory/domain/api/types/domainPrebidModuleDTO'
import { Schema, SchemaDomainPrebidModuleAttributes } from '../../../schema'
import { DomainPrebidModuleAttributeSliceDTO, Variables } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform((schema: Schema): Variables => {
    const { domainPrebidModules } = schema

    const parsed: DomainPrebidModuleAttributeSliceDTO[] =
      domainPrebidModulesToDTO(domainPrebidModules)
    const filtered: DomainPrebidModuleAttributeSliceDTO[] = filterEmptyParams(parsed)

    return {
      attributes: filtered,
    }
  })

const domainPrebidModulesToDTO = (
  domainPrebidModules: Schema['domainPrebidModules'],
): DomainPrebidModuleAttributeSliceDTO[] =>
  Object.entries(domainPrebidModules).map(
    ([key, { enabled, params }]: [
      key: keyof Schema['domainPrebidModules'],
      SchemaDomainPrebidModuleAttributes,
    ]): DomainPrebidModuleAttributeSliceDTO => ({
      enabled,
      id: key,
      params: parseSchemaParametersToDTO(params),
    }),
  )

const filterEmptyParams = (
  domainPrebidModules: DomainPrebidModuleAttributeSliceDTO[],
): DomainPrebidModuleAttributeSliceDTO[] =>
  domainPrebidModules.filter(({ params }: DomainPrebidModuleParamsDTO): boolean =>
    _.values(params).some(_.negate(_.isNull)),
  )
