import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import {
  Schema,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { parseSchemaParametersToDTO } from 'features/globalSetup/utils/parameter'
import { AdUnitBidderAttribute, Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const {
      adUnitBidders,
      domainIds: { domainBidderId },
      ...rest
    } = data

    const parsedAttributes: AdUnitBidderAttribute[] = parseAttributesToDTO(adUnitBidders)
    const filteredAttributes: AdUnitBidderAttribute[] = filterEmptyAttributes(parsedAttributes)

    return {
      adUnitBiddersAttributes: filteredAttributes,
      id: domainBidderId,
      ...rest,
    }
  })

const parseAttributesToDTO = (adUnitBidders: Schema['adUnitBidders']): AdUnitBidderAttribute[] =>
  Object.entries(adUnitBidders).map(
    ([key, { csEnabled, csParams, s2sEnabled, s2sParams }]: [
      key: keyof Schema['adUnitBidders'],
      SchemaAdUnitBidderProps,
    ]): AdUnitBidderAttribute => ({
      csEnabled,
      csParams: parseSchemaParametersToDTO(csParams),
      id: key,
      s2sEnabled,
      s2sParams: parseSchemaParametersToDTO(s2sParams),
    }),
  )

const filterEmptyAttributes = (attributes: AdUnitBidderAttribute[]): AdUnitBidderAttribute[] =>
  attributes.filter((attribute: AdUnitBidderAttribute): boolean => {
    const { csParams, s2sParams } = attribute

    const areCsParamsFilled: boolean = _.values(csParams).some(_.negate(_.isNull))
    const areS2sParamsFilled: boolean = _.values(s2sParams).some(_.negate(_.isNull))

    return areCsParamsFilled || areS2sParamsFilled
  })
