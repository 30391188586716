import { useContext } from 'react'

import { useMergeFormData } from 'components/Form'
import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleAccount } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModuleSchemaParameter } from 'features/inventory/domain/types/domainPrebidModule'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { InventoryConnectionContext } from '../../../contexts/InventoryConnectionContext'
import { useGetPrebidModulesDomains } from '../../hooks/useGetPrebidModuleDomains'
import {
  DomainPrebidModuleSlice,
  DomainPrebidModuleSortBy,
} from '../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../schema'
import { useColumns } from './columns/useColumns'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { DomainsTableProps } from './types'
import { prebidModuleDomainsToSchema } from './utils'

export const DomainsTable = ({ filters }: DomainsTableProps): JSX.Element => {
  const ability = useAbility()
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const { moduleType } = useContext(InventoryConnectionContext)
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<DomainPrebidModuleSlice, DomainPrebidModuleSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { count, loading, prebidModuleDomains } = useGetPrebidModulesDomains({
    filters,
    moduleType,
    workspacePrebidModuleId: workspacePrebidModule?.id,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const prebidModuleAccount: PrebidModuleAccount | undefined =
    workspacePrebidModule?.prebidModule?.account
  const canSeeParams: boolean = prebidModuleAccount
    ? ability.can(
        'read',
        `GlobalSetupField-prebid-modules-inventory-connection-params-${prebidModuleAccount}`,
      )
    : false
  const showParamsColumns: boolean = moduleType !== 'general' && canSeeParams

  const paramsSchema: DomainPrebidModuleSchemaParameter[] =
    prebidModuleDomains?.[0]?.paramsSchema || []

  const columns = useColumns({
    paramsSchema: showParamsColumns ? paramsSchema : [],
    prebidModuleAccount,
  })

  useMergeFormData<Schema>({
    data: prebidModuleDomainsToSchema({ moduleType, prebidModuleDomains }),
    path: 'domainPrebidModules',
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<DomainPrebidModuleSlice>
      columns={columns}
      data={prebidModuleDomains}
      data-cy={`${moduleType}-domains-table`}
      loading={loading}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}
